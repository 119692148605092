<template>
  <loading
    :active.sync="isLoading"
    :is-full-page="true"
    background-color="#000"
    color="#068bcd"
    :opacity="0.5"
    :width="70"
    :height="70">
  </loading>
</template>

<script>
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    props : {
      isLoading : Boolean,
    },
    components : {
      'Loading' : () => import('vue-loading-overlay'),
    },
  }
</script>